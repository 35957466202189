import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { firestore, auth } from "../../../firebase/firebase";
import { getDoc, doc as firebaseDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../firebase/requests";

// Assets
import trophy from "../../../assets/gifs/Trophy.gif";

// interfaces
import { completeChapter, isChapterCompleted } from "../../../utils/chapter";
import { Participant } from "../../../interfaces/Participant";
import { signInWithCustomToken } from "firebase/auth";

const CompleteChapter: React.FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();
    const { chapterId, isLibrary } = useParams<{ chapterId: string; isLibrary: string }>();
    const { currentParticipant, currentWeek } = useFetchUser();

    useEffect(() => {
        if (currentParticipant) {
            handleComplete();
        }
    }, [currentParticipant]);

    const handleComplete = async () => {
        if (currentParticipant && currentParticipant.id) {
            if (
                chapterId !== "L9JEcW0LVHgdT90SfPmi" &&
                chapterId !== "dBjDojoMPDWv3gvJCDjt" &&
                chapterId !== "h1BYNJTOhheE8tJ1khUk" &&
                isChapterCompleted(chapterId, currentParticipant)
            ) {
                // Short-circuit if chapter is already completed
                return hist.replace(isLibrary === "true" ? "/library" : "/home");
            } else {
                // Complete the chapter and update count concurrently
                await Promise.all([completeChapter(currentParticipant.id, currentWeek, chapterId), updateCompletedChapterCount()]);
                
                enqueueSnackbar(
                    <span>
                        {t(`1pointCompletedGained`)}
                        <b> 1 point </b>
                        {t(`1pointCompletedChapter`)}
                    </span>,
                    {
                        variant: "success",
                        anchorOrigin: {
                            horizontal: "center",
                            vertical: "bottom",
                        },
                        content: (key, message) => (
                            <div key={key} className="point__snackbar">
                                <img alt="trophy" src={trophy} />
                                <p>{message}</p>
                            </div>
                        ),
                        autoHideDuration: 5000,
                    }
                );
                await redirect();
            }
        }
    };

    const redirect = useCallback(async () => {
        try {
            if (isLibrary === "true") {
                return hist.replace("/library");
            }

            // Replace with optimized navigation
            switch (chapterId) {
                case "z9FrQXipd4aNRgDmTzXC":
                    hist.replace("/questionnaires/stage1");
                    break;
                case "L9JEcW0LVHgdT90SfPmi":
                    hist.replace(currentParticipant?.goals["stage1"].length ? "/home" : "/overview");
                    break;
                case "dBjDojoMPDWv3gvJCDjt":
                    hist.replace("/prioritizedTopics");
                    break;
                case "h1BYNJTOhheE8tJ1khUk":
                    hist.replace("/goals");
                    break;
                default:
                    hist.replace("/home");
            }
        } catch (e) {
            console.error(e);
        }
    }, [chapterId, currentParticipant?.goals, isLibrary, hist]);

    const updateCompletedChapterCount = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(firebaseDoc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        if (participant.uses[index].chaptersCompleted) {
                            participant.uses[index].chaptersCompleted.push(chapterId);
                        } else {
                            participant.uses[index].chaptersCompleted = [chapterId];
                        }
                        await updateDoc(firebaseDoc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    };

    return null;
};

export default CompleteChapter;
