import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { auth, functions } from "../../../firebase/firebase";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { httpsCallable } from "firebase/functions";
import { useLocation } from "react-router-dom";

const SubDomainRedirection = () => {
    const query = useQuery();
    const { currentParticipant, fetchingUser } = useFetchUser();
    const { pathname } = useLocation();

    useEffect(() => {
        const syn2Redirection = async () => {
            if (fetchingUser) return; // Wait for user data

            const referrer = document.referrer;
            const isFromSubdomain = referrer.includes("syn2.brainhealthpro.ca") || referrer.includes("sleep-library.brainhealthpro.ca");
            const isCompletedUrl = window.location.href.includes("completed");

            // Handle chapter completion before redirecting
            if (isCompletedUrl) {
                console.log("Handling chapter completion");
                return; // Do not redirect, let the CompleteChapter component handle it
            }

            // Redirect user back to subdomain after chapter completion
            if (isFromSubdomain && isCompletedUrl) {
                console.log("Redirecting user back to subdomain:", referrer);
                window.location.href = referrer;
                return;
            }

            if (currentParticipant?.id && currentParticipant.study && !window.location.href.includes("completed/")) {
                const tokenQuery = query.get("token");

                if (!tokenQuery) {
                    const createCustomToken = httpsCallable(functions, "createCustomToken");
                    const tokenPayload = await createCustomToken({ uid: currentParticipant.id });
                    const authToken = tokenPayload.data as string;
                    localStorage.setItem("authToken", authToken);

                    // Redirect based on study
                    if (currentParticipant.study === "synergic2") {
                        await auth.signOut();
                        window.location.href = `https://syn2.brainhealthpro.ca/?token=${authToken}`;
                    } else if (currentParticipant.study === "sleep") {
                        await auth.signOut();
                        window.location.href = `https://sleep-library.brainhealthpro.ca/?token=${authToken}`;
                    }
                }
            }
        };

        syn2Redirection();
    }, [currentParticipant, fetchingUser, pathname]);

    return null;
};

export default SubDomainRedirection;
