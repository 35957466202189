import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// React-router-dom
import { Link } from "react-router-dom";

// Interfaces
import { Participant } from "../../../interfaces/Participant";
import { Chapter, ChapterTopic } from "../../../interfaces/Chapter";

// Components
import Title from "../../general/Title";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { getIconFromTopic, getTitleFromTopic } from "../../../utils/topic";

// MUI
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Utils
import settings from "../../../settings.json";

// Assets
import { HiHeart, HiOutlineHeart } from "react-icons/hi";
import noResult from "../../../assets/icons/icon-no-result.png";

const TopicsCompleted: React.FC = () => {
    const { t } = useTranslation();
    const { currentParticipant } = useFetchUser();
    const participantRequest = useDb<Participant>("Participants", null, currentParticipant);
    const chapterRequest = useDb<Chapter>("Chapters", null, currentParticipant);

    // States
    const [participant, setParticipant] = useState<Participant | null>(null);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [selectedTab, setSelectedTab] = useState<ChapterTopic>("Brain Overview");
    const [isMounted, setIsMounted] = useState(true);

    const fetchFavourites = () => {
        if (currentParticipant && currentParticipant.id) {
            return participantRequest.onSnapshot({
                callback: setParticipant,
                id: currentParticipant.id,
            });
        }
    };

    const getChapters = async () => {
        if (currentParticipant && currentParticipant.completedChapters) {
            const filterChapters = currentParticipant.completedChapters["Brain Overview"].concat(
                currentParticipant.completedChapters["Cognitive"],
                currentParticipant.completedChapters["Nutrition"],
                currentParticipant.completedChapters["Physical"],
                currentParticipant.completedChapters["Sleep"],
                currentParticipant.completedChapters["Social Psychological"],
                currentParticipant.completedChapters["Vascular Health"],
                currentParticipant.completedChapters["Vision Hearing"]
            );

            const localChapters = [];

            if (filterChapters.length > 0) {
                for (const chapterId of filterChapters) {
                    const c = (await chapterRequest.getById(chapterId)) as Chapter;

                    localChapters.push(c);
                }
                setChapters(localChapters);
            }
        }
    };

    useEffect(() => {
        setIsMounted(true); // Set mounted to true when the component is mounted
        if (currentParticipant) getChapters();

        return () => {
            setIsMounted(false); // Set mounted to false when the component is unmounted
        };
    }, [currentParticipant]);

    useEffect(() => {
        const unsub = fetchFavourites();

        return () => {
            if (typeof unsub === "function") unsub();
        };
    }, []);

    const handleSetFavourite = async (c: Chapter) => {
        if (currentParticipant && currentParticipant.id && c.id && participant) {
            let list: string[] = [];
            if (participant.favourites) list = participant.favourites;
            // if already favourited
            if (list.includes(c.id)) {
                list = list.filter(chap => chap !== c.id);
            } else {
                list.push(c.id);
            }
            participantRequest.updateDoc({ ...participant, favourites: list } as Participant);
        }
    };

    interface Props {
        image: any;
        title: string;
        chapter: Chapter | any;
        topic: string | any;
    }

    const isChapterHidden = (chapterId: string) => {
        if (currentParticipant) {
            if (chapterId === "HfynqtVV8rA1e0YZTgPc" && currentParticipant?.vascularChapter7HiddenDate) {
                return true;
            }
            if (chapterId === "uR241p2bUtnWTj0e1WTU" && currentParticipant?.vascularChapter8HiddenDate) {
                return true;
            }
        }
        return false;
    };

    const TopicCard: React.FC<Props> = ({ image, title, chapter, topic }) => {
        return (
            <Link to={`/chapter/${chapter.id}`}>
                <div className="taskCard" style={{ opacity: isChapterHidden(chapter.id) ? 0.3 : 1 }}>
                    <div className="taskCard__logo">
                        <img src={image} alt="" className="nofilterimg" />
                    </div>
                    <div className="taskCard__content">
                        <div className="taskCard__firstLine">
                            <h5>
                                {topic} <span className="taskCard__separation">|</span> Chapter {chapter.chapter}
                            </h5>
                        </div>
                        <h3>{title}</h3>
                    </div>
                    <div
                        className="taskCard__topRightCorner taskCard__pointer"
                        onClick={() => {
                            if (!isChapterHidden(chapter.id)) handleSetFavourite(chapter);
                        }}
                    >
                        {participant && participant.favourites && chapter.id && participant.favourites.includes(chapter.id) ? (
                            <div className="taskCard__fullHearth">
                                <HiHeart />
                            </div>
                        ) : (
                            <HiOutlineHeart />
                        )}
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <div className="topicsFavourited">
            <header>
                <Title>{t("completed")}</Title>

                <Box sx={{ width: "100%" }}>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        onChange={(event: React.SyntheticEvent, newValue: ChapterTopic) => setSelectedTab(newValue)}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        {settings.app.topics.map(t => (
                            <Tab
                                key={t}
                                value={t}
                                label={
                                    <Tooltip title={getTitleFromTopic(t as ChapterTopic)}>
                                        <IconButton>
                                            <img src={getIconFromTopic(t as ChapterTopic)} alt={t} style={{ maxWidth: 35 }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </Tabs>
                </Box>

                <p style={{ marginTop: 20 }}>{t("completedDesc")}</p>
            </header>

            <div className="tf__cardContainer" style={{ marginTop: 0 }}>
                <h5 style={{ paddingTop: 20 }}>{getTitleFromTopic(selectedTab)}</h5>
                {chapters && chapters.filter(c => c.topic === selectedTab).length ? (
                    chapters
                        .sort((a, b) => (a.chapter < b.chapter ? -1 : 1))
                        .map(c => {
                            if (c.topic === selectedTab) {
                                return (
                                    <Grid item xs={12} key={c.id}>
                                        <TopicCard
                                            image={getIconFromTopic(c.topic)}
                                            chapter={c}
                                            title={localStorage.getItem("language") === "en" ? c.titleEN : c.titleFR}
                                            topic={getTitleFromTopic(c.topic)}
                                        />
                                    </Grid>
                                );
                            }
                        })
                ) : (
                    <Grid item xs={12}>
                        <img src={noResult} alt="" />
                    </Grid>
                )}
            </div>
        </div>
    );
};

export default TopicsCompleted;
